@import url('https://fonts.googleapis.com/css?family=Kanit:regular,bold,italic&subset=latin,latin-ext');

@media print {
  .disabled-print {
    display: none;
  }
}

.products{
  margin-top: 180px !important;
}

body {
  background-color: transparent;
  font-family: Kanit;
}

.toast-container {
  z-index: 20 !important;
}

.fixed-top {
  z-index: 15;
}

.bg-blue {
  background-color: #2E3191;
}

.custom-themes {
  background-color: transparent;
  color: #ccc;
}

.main {
  margin-top: 75px;
  margin-bottom: 75px;
}

.menu-bar {
  display: none;
}

.link-btn {
  border: 1px solid #51CF7D;
  border-radius: 3px;
  padding: 0 5px 0 5px;
  background-color: #51CF7D;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

.cart {
  position: relative;
  padding: 12px;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: 20px;
  box-shadow: 0 .325rem .35rem rgba(0, 0, 0, .075) !important;
}

.cart-title {
  font-weight: 800;
  margin-top: 20px;
}

.category .items {
  margin: 10px;
}

.shadow-item {
  position: relative;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-inner-border-radius);
  box-shadow: 0 .325rem .35rem rgba(0, 0, 0, .075) !important;
}

/* 
.checkbox-lg{
  padding-top: 30px;
  padding-left: 30px;
  background-color: #000 !important;
}
  */


/** Check Box*/
.checkbox {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
}

.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}

.checkbox label {
  position: relative;
}

.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 22px;
  height: 22px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid #000;
}

.checkbox label:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 12px;
  left: 10px;
}

.checkbox input[type="checkbox"]:checked~label::before {
  color: #fff;
  background-color: #000;
}

.checkbox input[type="checkbox"]:checked~label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox label {
  min-height: 34px;
  display: block;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.checkbox input[type="checkbox"]:focus+label::before {
  outline: 0;
}

/** End Check Box*/

.icon-menu-left {
  display: none;
}

.menu-left {
  position: fixed;
  z-index: 20;
  transform: translate3d(-75vw, 0, 0);
  width: 75vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(40, 10, 200, 0.874);
}

.fadeIn {
  opacity: 1;
  transform: translate3d(0vw, 0, 0);
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
}

.fadeOut {
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
}

.menu-gear {
  margin-top: 5%;
}

.top-menu-height {
  height: 10vh;
}

.shadow-logo {
  width: 5vw;
}

.pointer {
  cursor: pointer;
}

.absolute-right {
  position: absolute;
  right: 1%;
}

input.transparent-input {
  background-color: transparent !important;
  border: none !important;
}

@media only screen and (min-width: 600px) {
  .main-logo {
    max-width: 120px;

  }

  .menu-left {
    width: 25vw;
  }

  .login {
    max-width: 400px !important;
  }

  .register {
    max-width: 800px !important;
  }

}

.items-list {
  max-width: 160px;
}

.items-list>img {
  max-width: 150px;
}

@media only screen and (max-width: 600px) {

  body {
    touch-action: pan-x pan-y;
    /* disabled zoom */
  }

  .top-menu-height {
    height: 25vh;
  }

  .shadow-logo {
    width: 20vw;
    -webkit-filter: drop-shadow(5px 5px 5px rgb(152, 152, 152));
    filter: drop-shadow(5px 5px 5px rgb(152, 152, 152));
  }

  .login {
    background-color: #2D318C;
    color: #fff;
    margin: 0;
  }

  .main {
    margin-top: 10px;
    margin-bottom: 75px;
  }

  .menu-bar {
    display: block;
    z-index: 10;
    position: fixed;
    bottom: -1px;
    left: 0px;
    height: 60px;
    width: 100%;
    background-color: #2B2682;
    padding-top: 10px;
    border-top: 1px groove #3e38b1;
  }

  .menu-bar svg {
    font-size: 20px
  }

  .menu-bar .text-link {
    font-size: 10px;
  }

  .menu-bar .items {
    text-align: center;
    border-left: 1px solid #ccc;
  }

  .nav-header {
    display: none;
  }

  /** Product cart */
  .cart {
    padding: 0 12px 0 12px;
  }

  .cart .cart-desc {
    font-size: 10px;
  }

  .cart-title {
    margin-top: 0px;
  }


  .category .items {
    margin: 0;
  }



  /** btn */
  .link-btn {
    font-size: 10px;
  }

  .icon-menu-left {
    display: block;
    position: absolute;
    top: 50%;
    left: 3%;
    z-index: 10;
    font-size: 25px;
  }

  .shadow-item {
    max-width: 25vw;
    border-radius: 5px;
  }

  .mobile-content {
    margin-top: 110px;
  }

  .mobile-content-row {
    margin-top: 20px;
  }

  .items-list {
    max-width: 140px;
  }

  .items-list>img {
    max-width: 130px;
  }
}


.font-8 {
  font-size: 8px;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-14 {
  font-size: 14px;
}

/* Loading */
.loading-box {
  height: 20vh;
  width: 100%;
  display: flex;
  position: fixed;
  left: 20%;
  top: 35%;
}

.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}

.wave:nth-child(3) {
  animation-delay: 0.2s;
}

.wave:nth-child(4) {
  animation-delay: 0.3s;
}

.wave:nth-child(5) {
  animation-delay: 0.4s;
}

.wave:nth-child(6) {
  animation-delay: 0.5s;
}

.wave:nth-child(7) {
  animation-delay: 0.6s;
}

.wave:nth-child(8) {
  animation-delay: 0.7s;
}

.wave:nth-child(9) {
  animation-delay: 0.8s;
}

.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}